<template>
    <div>
        <HeaderXS></HeaderXS>
        <div class="container">
            <div class="row">
                <h2 class="col-12 text-center">Ihre Buchung bei<br> Waschprofi</h2>
            </div>
        </div>
        <CustomerDetailsXS></CustomerDetailsXS>
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h3>Zahlung</h3>
                </div>
                <div class="col-12">
                    <form>
                        <div class="form-check payment-list-entry">
                            <input type="radio" value="sofort" name="payment" id="sofort" class="form-check-input" v-model="paymentType">
                            <label for="sofort" class="form-check-label">
                                <img src="https://www.mollie.com/external/icons/payment-methods/sofort.svg"> SOFORT Überweisung
                            </label>
                        </div>
                        <div class="form-check payment-list-entry">
                            <input type="radio" value="giropay" name="payment" id="giropay" class="form-check-input" v-model="paymentType">
                            <label for="giropay" class="form-check-label">
                                <img src="https://www.mollie.com/external/icons/payment-methods/giropay.svg"> Giropay
                            </label>
                        </div>
                        <div class="form-check payment-list-entry">
                            <input type="radio" value="creditcard" name="payment" id="creditcard" class="form-check-input" v-model="paymentType">
                            <label for="creditcard" class="form-check-label">
                                <img src="https://www.mollie.com/external/icons/payment-methods/creditcard.svg"> Kreditkarte
                            </label>
                        </div>
                        <div class="form-check payment-list-entry">
                            <input type="radio" value="paypal" name="payment" id="paypal" class="form-check-input" v-model="paymentType">
                            <label for="paypal" class="form-check-label">
                                <img src="https://www.mollie.com/external/icons/payment-methods/paypal.svg"> PayPal
                            </label>
                        </div>
                    </form>
                </div>
                <div class="col-12 blue-bg coupon" v-if="cart.product != 'coupon'">
                    <strong>Gutschein einlösen? </strong>
                    <input type="text" placeholder="Gutscheincode eintragen" v-model="cart.coupon">
                    <a @click.prevent="checkCoupon" class="button blue">Einlösen</a>
                </div>
                <div class="col-12 coupon-response" v-if="couponText">
                    <div v-html="couponText"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <h3>Zusammenfassung</h3>
                </div>
            </div>

            <CartTableXS :coupon="coupon" v-if="cart.product != 'coupon'"></CartTableXS>
            <CouponCartTableXS v-if="cart.product == 'coupon'"></CouponCartTableXS>

            <div class="row">

                <div class="col-12 agb-check" :class="{ 'form-group--error': $v.checked.$error }">
                    <div class="form-check form-check-inline" :class="{ 'form-group--error': $v.checked.$error }">
                          <input class="form-check-input" type="checkbox" name="checked" id="checked" v-model="checked"  :class="{ 'is-invalid': $v.checked.$error }" v-model.trim="$v.checked.$model"  @change="$v.checked.$touch()" required>
                          <label class="form-check-label" for="checked">Ich erkläre mich mit den <router-link to="terms" target= '_blank'>allgemeinen Geschäftsbedingungen</router-link> und Bestimmungen zum <router-link to="privacy" target= '_blank'>Datenschutz</router-link> einverstanden.</label>
                    </div>

                          <div class="error" v-if="!$v.checked.sameAs"><br>Sie müssen die allgemeinen Geschäftsbedingungen und die Datenschutz erklärung akzeptieren.</div>
                </div>

                <div class="col-12 text-center" v-if="error">
                    <b-alert show variant="danger">
                        Ihre Zahlung ist fehlgeschlagen, oder wurde abgebrochen!
                    </b-alert>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <div class="form-group">
                        <button @click.prevent="prepareOrder()" class="button invert" :disabled="loading">
                            <span v-html="CheckOutText"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <FooterXS></FooterXS>
    </div>
</template>
<script>
    import CustomerDetailsXS from "@/components/CustomerDetailsXS.vue";
    import CartTableXS from "@/components/CartTableXS.vue";
    import CouponCartTableXS from "@/components/CouponCartTableXS.vue";
    import HeaderXS from "@/components/HeaderXS.vue";
    import FooterXS from "@/components/FooterXS.vue";

    import axios from 'axios';

    import { sameAs } from 'vuelidate/lib/validators'

    export default {
        name: 'CheckOutXS',

        components: {
            CustomerDetailsXS,
            CartTableXS,
            CouponCartTableXS,
            HeaderXS,
            FooterXS
        },
        data() {
            return {
                error: this.$store.state.error,
                payment_methods: {

                },
                user_data: this.$store.state.user_data.user_data,
                cart: this.$store.state.cart,
                CheckOutText: 'Zahlungspflichtig bestellen',
                CheckOutUrl: '',
                loading: false,
                couponText: '',
                paymentType: '',
                checked: false,
                coupon: false,
            }
        },
        watch: {
            paymentType(){
                this.CheckOutText = 'Zahlungspflichtig bestellen';
                this.loading = false;
            }
        },
        methods: {
            prepareOrder() {
                this.loading =  true
                this.CheckOutText = "Bitte warten"
                axios.interceptors.request.use(function(config) {
                    var container = document.querySelector('#app');
                    var loader = document.querySelector('.loader');
                    if(container && !loader){
                        var temp = `
                        <center><br><br><br>
                        <div class="loader" id="loader"></div>
                        <div class="loader" id="loader2"></div>
                        <div class="loader" id="loader3"></div>
                        <div class="loader" id="loader4"></div>
                        <span id="text">Sie werden zu unserem Zahlungsanbieter weiter geleitet</span><br>
                        </center>
                        `;

                        // document.querySelector('#app > div').style.display = 'none';
                        container.innerHTML = temp + container.innerHTML;
                    }
                    return config
                }, function(error) {
                    this.loading =  false;
                    document.querySelector('#app').removeChild(document.querySelector('center'));
                    this.CheckOutText = "Es ist ein Fehler aufgetreten"
                    return Promise.reject(error);
                });

                if(this.paymentType){
                    axios
                        .post(`https://api.opel-dresden.de/api/payment/prepare`, { "method": this.paymentType, "user_data": this.user_data, "cart": this.cart}, {
                        })
                        .then(response => {
                            this.CheckOutUrl = response.data.data;
                            this.cart.order = response.data.data.split("/").pop()
                        })
                        .catch(function(error) {
                            console.log(error);
                        }).finally(() => {
                            this.loading =  false
                            window.location = this.CheckOutUrl;
                        });
                    }else{
                        this.CheckOutText = "Bitte wählen Sie ein Zahlungsmethode aus.";
                    }
            },
            checkCoupon(){
                axios
                    .post(`https://api.opel-dresden.de/api/check_coupon`, { "coupon": this.cart.coupon}, {
                    })
                    .then(response => {
                        if(!response.data.data){
                            this.couponText = "Ungültiger Gutscheincode!"
                        }else {
                            if(response.data.data.car_rental){
                                this.couponText += "Sie erhalten für den Zeitraum der Reinigung einen kostenlosen Mietwagen.<br>"
                            }
                            if(response.data.data.value.indexOf("%") !== -1){
                                this.couponText += `Sie erhalten einen Rabatt im Wert von ${response.data.data.value}<br>`
                                this.coupon = response.data.data.value
                            }else if(response.data.data.value !== ""){
                                this.couponText += `Sie erhalten einen Rabatt im Wert von ${response.data.data.value} €<br>`
                                this.coupon = response.data.data.value
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
        },
        validations () {
            return {
                checked: {
                    sameAs: sameAs( () => true )
                },
            }
        }
    }
</script>
<style lang="scss" scoped>


    .agb-check{
        margin-top: 15px;
        margin-bottom: 25px;
    }

    h2{
        font-family: 'futura-pt-bold';
        font-size: 28px;
        letter-spacing: 0.7px;
        line-height:34px;
        margin-bottom:10px;
    }

    h3{
        font-size:20px;
        font-family: 'futura-pt-bold';
        letter-spacing: 0.5px;
        margin-top:35px;
        line-height:42px;
    }

    .form-check-inline{
        padding-left: 1.25rem;

        label{
            padding-left: .5rem;
        }
    }

    .white-bg{
        background-color: #FFF;
        margin-top: 65px;

        .table{
            thead{
                border-bottom: thin solid #000 !important;
                font-size: 21px;
            }

            tbody{

                td{
                    font-size: 21px;
                    font-weight: 400;

                    .article{
                        font-size: 25px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .blue-bg.coupon{
        padding:45px;
        font-size:17px;
        margin-top:25px;
        margin-bottom:0;

        input{
            height:40px;
            width:100%;
            margin-bottom:20px;
            margin-top:20px;
            padding: 0.375rem 0.75rem;
        }
    }

    .coupon-response{
        font-size:17px;
        letter-spacing: 0.34px;
        line-height: 20px;
        padding: 20px 45px;
        background-color:#456FEF;
        color:#FFF;
    }

    .form-group--error .error {
        margin-top: 10px;
        display: block;
        color: #dc3545;
    }
    .error {
        font-size: 0.85rem;
        line-height: 1;
        display:none;
    }

    form{

        .payment-list-entry{
            background-color: #edeef0;
            font-size:21px;
            margin-bottom: 15px;
            padding-top:20px;
            padding-bottom:20px;
            padding-left: 40px;

            input{
                margin-top: .75rem;
            }

            label{
                font-family: 'futura-pt';

                img{
                    margin-right:15px;
                }
            }

            input:checked+label{
                font-family: 'futura-pt-bold';
                img{
                    border: thin solid #e3eafd;
                }
            }
        }

        .form-control{
            height: 65px;
            border: none;
        }
    }

    .button:enabled{
        background-color:#F04D59;
        color:#FFF
    }


</styles>