<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h3>Rechnungsadresse</h3>
                </div>
            </div>
        </div>
        <div class="blue-bg">
            <div class="container">
                <dl class="row" v-if="user_data.customer_type == 'company'">
                    <dt class="col-lg-3">Firma:</dt>
                    <dd class="col-lg-6 offset-lg-1">{{ user_data.company }}</dd>
                </dl>
                <dl class="row" v-if="user_data.customer_type == 'company'">
                    <dt class="col-lg-3">USt.-Nr.:</dt>
                    <dd class="col-lg-6 offset-lg-1">{{ user_data.company_tax_number }}</dd>
                </dl>
                <dl class="row">
                    <dt class="col-lg-3">Name, Vorname:</dt>
                    <dd class="col-lg-6 offset-lg-1">{{ user_data.title }} {{ user_data.lastname }}, {{ user_data.firstname }}</dd>
                </dl>
                <dl class="row">
                    <dt class="col-lg-3">Straße/Nr.:</dt>
                    <dd class="col-lg-6 offset-lg-1">{{ user_data.street }}</dd>
                </dl>
                <dl class="row">
                    <dt class="col-lg-3">PLZ/Ort:</dt>
                    <dd class="col-lg-6 offset-lg-1">{{ user_data.zipcode }} {{ user_data.city }}</dd>
                </dl>
                <dl class="row">
                    <dt class="col-lg-3">Telefon:</dt>
                    <dd class="col-lg-6 offset-lg-1">+49 {{ user_data.phone }}</dd>
                </dl>
                <dl class="row">
                    <dt class="col-lg-3">E-Mail:</dt>
                    <dd class="col-lg-6 offset-lg-1">{{ user_data.mail }}</dd>
                </dl>
                <a href="booking_form" class="button black ">Ändern</a>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'CustomerDetails',

        components: {
        },
        data() {
            return {
                user_data: this.$store.state.user_data.user_data
            }
        }
    }
</script>
<style lang="scss" scoped>

    h3{
        font-family: 'futura-pt-bold';
        font-size:20px;
        letter-spacing: 0.5px;
        line-height: 30px;
    }

    .blue-bg{
        padding-top:35px;
        .container{
            padding-top: 0px;
            padding-left:20px;
            padding-right:20px;

            dt,
            dd{
                font-size: 17px;
                letter-spacing: 0.42px;
                line-height: 24px;
            }

            .button{
                margin-top: 35px;
            }
        }
    }

</styles>